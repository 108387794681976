@import "../../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
/* @import "../../../../../../"; */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800");
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;600;700&display=swap");

body {
  font-family: "Montserrat" !important;
  text-align: center !important;
  width: 100%;
  height: 100%;
  background-color: #ffffff !important;
}

.center_keeping_conatiner {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
}

button.disbalesubmitbtn {
  text-transform: none !important;
  border-radius: 2px;
  background: #ffffff !important;
  width: 420px !important;
  height: 42px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  opacity: 0.5;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

button.cancelbtn {
  text-transform: none !important;
  border-radius: 2px;
  background: #ffffff !important;
  width: 420px !important;
  height: 42px;
  margin-top: 20px;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  margin-bottom: 50px;
  background: #ffffff;
  border: 1px solid #c09507;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

button.editbtn {
  text-transform: none !important;
  border-radius: 2px;
  background: #ffffff !important;
  width: 420px !important;
  height: 42px;
  margin-top: 30px;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  margin-top: 50px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #585d60;
}

button.submitbtn {
  width: 420px !important;
  text-transform: none !important;
  color: #ffffff;
  height: 42px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  background: #c09507;
  border-radius: 2px;
  border: 2px solid #c09507 !important;
  box-shadow: none !important;
}

button.submitbtn:hover {
  background-color: #ab8405 !important;
}

.width100percent {
  width: 100%;
}

.mainheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 138.69%;
  color: #3d4a52;
}

.editheading {
  font-weight: 700;
  font-size: 20px;
  color: #3d4a52;
}

.secondmainheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 138.69%;
  color: #3d4a52;
}

.fontweight600andfontsize14pxandcolor3A3A3A {
  font-weight: 600;
  font-size: 14px;
  color: #3a3a3a;
}

.fontweight600andfontsize14pxandcolorFFFFFF {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

.fontweight600andfontsize14pxandcolor3D4A52 {
  font-weight: 600;
  font-size: 14px;
  color: #3d4a52;
}

.fontweight400andfontsize14pxandcolor3D4A52 {
  font-weight: 400;
  font-size: 14px;
  color: #3d4a52;
}

.fontweight400andfontsize16pxandcolor3D4A52 {
  font-weight: 400;
  font-size: 16px;
  color: #3d4a52;
}

.thirdmainheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138.69%;
  color: #3d4a52;
}

.mainheadingsuccess {
  font-weight: 700;
  font-size: 20px;
  color: #3d4a52;
  font-family: "Montserrat";
  font-style: normal;
}

.secondmainheadingsuccess {
  font-weight: 700;
  font-size: 20px;
  color: #d8af28;
  font-family: "Montserrat";
  font-style: normal;
}

.thirdmainheadingsuccess {
  font-weight: 400;
  font-size: 16px;
  color: #3d4a52;
  font-family: "Montserrat";
  font-style: normal;
}

.css-1gsv261 {
  border-bottom: 1px solid #c09507 !important;
}

.boldimage {
  display: none;
}

.nonboldimage {
  display: block;
}

.react-multi-email > input {
  background: none !important;
}

.react-multi-email {
  width: 100% !important;
  border: none !important;
  background: none !important;
  border-bottom: 1px solid #9aa1a9 !important;
  border-radius: inherit !important;
}

.invite-participant-text-editor > div:first-child {
  background: #f1f1f1 !important;
  border: none !important;
  border-bottom: 1px solid #9aa1a9 !important;
  border-radius: inherit !important;
}

.invite-participant-text-editor > div:first-child > div:first-child {
  text-align: left;
}

.settingsTabs .Mui-selected {
  color: #ffffff !important;
  border-radius: 2px 0px 0px 0px;
  text-transform: none !important;
  background: #c09507;
  width: 220px;
  height: auto;
  font-weight: 400;
  border-bottom: none !important;
  border-left: 1px solid #d8af28 !important;
  border-right: 1px solid #d8af28 !important;
  border-top: 1px solid #d8af28 !important;
}

.particaipancard .MuiCardHeader-title {
  padding-bottom: 18px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #3d4a52;
  line-height: 19px;
}

.particaipancard .MuiCardHeader-subheader {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  opacity: 0.5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.downloadDataset {
  color: #3491ee !important;
}

.downloadDataset:hover {
  text-decoration: underline !important;
}

.MuiDataGrid-columnHeaderTitle {
  text-transform: capitalize !important;
}

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #a3b0b8 !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.css-19mk8g1-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: 2px solid #a3b0b8 !important;
}

.MuiFilledInput-input,
.MuiInputLabel-root {
  color: #3d4a52 !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 19px !important;
}

.css-1wc848c-MuiFormHelperText-root {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.css-1wc848c-MuiFormHelperText-root.Mui-error {
  color: #ff3d00 !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

button.disbalesubmitbtnteam {
  text-transform: none !important;
  border-radius: 2px;
  box-shadow: none !important;
  background: #ffffff !important;
  width: 420px !important;
  height: 42px;
  margin-top: 200px;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  opacity: 0.5;
  font-weight: 700;
}

button.submitbtnteam {
  width: 420px !important;
  text-transform: none !important;
  color: #ffffff;
  height: 42px;
  margin-top: 200px;
  background: #c09507;
  border-radius: 2px;
  border: 2px solid #c09507 !important;
  box-shadow: none !important;
  font-weight: 700;
}

button.submitbtnteam:hover {
  background-color: #ab8405 !important;
}

button.submitbtn:hover {
  background-color: #ab8405 !important;
}

button.cancelbtnteam {
  text-transform: none !important;
  border-radius: 2px;
  background: #ffffff !important;
  width: 420px !important;
  height: 42px;
  margin-top: 30px;
  font-weight: 700;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  margin-bottom: 50px;
  box-shadow: none !important;
}

.backlabel {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138.69%;
  color: #3491ee;
}

.link :hover {
  cursor: pointer;
}

.react-multi-email [data-tag] {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #3d4a52 !important;
}

.public-DraftEditorPlaceholder-root {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #3d4a52 !important;
}

.tabstyle {
  width: 109px;
  height: 19px;
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-weight: 400;
}

.buttonremovebackgroundonhover:hover {
  background: none !important;
}

.widht640andheight368pxandborderradius10andborder1pxsolidE4E4E4 {
  width: 640px;
  height: 368px;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
}

.separatorline {
  margin-left: -200px;
  margin-right: -200px;
  margin-top: 30px;
  border-top: 1px solid rgba(238, 238, 238, 0.5);
}

.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  color: #3d4a52 !important;
}

.MuiLinearProgress-bar1Indeterminate {
  background-color: #00a94f !important;
}

.MuiLinearProgress-bar2Indeterminate {
  background-color: #00a94f !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: white !important;
}

MuiLinearProgress-determinate {
  background-color: #00a94f !important;
}

.MuiLinearProgress-colorSuccess MuiLinearProgress-determinate {
  background-color: white !important;
}

/* hiding drag and drop shadow issue */
.sc-gsnTZi {
  display: none;
}

/* checkbox */
.Mui-checked {
  color: #c09507 !important;
}

/* switch */
.MuiSwitch-track {
  background-color: #c09507 !important;
}

/* mui typography  */
.MuiTypography-root {
  font-family: "Montserrat" !important;
}

.tocapitalizethefirstletter {
  text-transform: capitalize;
}

.memberDataSetCardTooltipAndWidthAndOverflowWidth200 {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px !important;
}

.memberDataSetCardTooltipAndWidthAndOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_overflow_ellipsis_overflow_hidden {
  text-overflow: ellipsis;
  overflow: hidden;
}

.width100px {
  width: 100px;
}

.width150px {
  width: 150px;
}

.width173px {
  width: 173px;
}

.width200px {
  width: 200px;
}

.width250px {
  width: 250px;
}

.width260px {
  width: 250px;
}

.width270px {
  width: 270px;
}

.width300px {
  width: 300px;
}

.width300px_mt5px {
  width: 300px;
  margin-top: 5px;
}

.width600px {
  width: 600px;
}

.width1000px {
  width: 1000px;
}

.classForPtag {
  margin: 0px auto;
  word-wrap: break-word;
}

.carousel-indicators {
  position: absolute;
  right: 0px;
  bottom: 0;
  left: 173px !important;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
  width: 72px !important;
}

.carousel-control-next,
.carousel-control-prev {
  z-index: 200 !important;
}

.carousel-control-next {
  right: 241px !important;
  height: 200px !important;
  top: 271px !important;
  height: 30px !important;
  width: 30px !important;
  background-image: url("../Img/next.png");
  background-repeat: no-repeat;
  background-position: center !important;
}

.carousel-control-prev {
  background-image: url("../Img/back.png");
  background-repeat: no-repeat;
  left: 240px !important;
  height: 200px !important;
  top: 271px !important;
  height: 30px !important;
  width: 30px !important;
  background-position: center !important;
}

.carousel-control-prev-icon {
  display: none !important;
}

.carousel-control-next-icon {
  display: none !important;
}

.cardheaderTitlespecifier .css-et1ao3-MuiTypography-root {
  width: 250px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.cardheaderTitlespecifier .css-et1ao3-MuiTypography-root {
  width: 250px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.cardheaderTitlespecifier .css-83ijpv-MuiTypography-root {
  width: 250px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.red {
  border: 1px solid red;
}

.successIconInsideCardheader {
  width: 22px;
  height: 21px;
}

.MuiCardHeader-content {
  position: relative;
}

.MuiCardHeader-content .successIconInsideCardheader {
  position: absolute;
  right: 11px;
  bottom: 0px;
}

.dockerImageURL {
  color: #3491ee;
  cursor: pointer;
}

.minHeightWithoutFooter {
  min-height: 500px;
}

.minHeight232px {
  min-height: 232px;
}

.minHeight501pxsettingpagemaindiv {
  min-height: 501px;
}

.minHeight67vhParticipantPage {
  min-height: 67.5vh;
  /* margin-top: 100px; */
}

.minHeight67vhDatahubPage {
  /* margin-top: 100px; */
  min-height: 67.5vh;
}

.hover {
  cursor: pointer;
}

.Departmentcard .MuiCardHeader-title {
  padding-bottom: 1px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #3d4a52;
  line-height: 19px;
  margin-left: 8px;
}

.Departmentcard .MuiCardHeader-subheader {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 8px;
  /* identical to box height */

  color: #000000;

  opacity: 0.5;
}

button.submitbtndept {
  width: 420px !important;
  text-transform: none !important;
  color: #ffffff;
  margin-top: 80px;
  height: 42px;
  background: #c09507;
  border-radius: 2px;
  border: 2px solid #c09507 !important;
  box-shadow: none !important;
  font-weight: 700;
}

button.submitbtndept:hover {
  background-color: #ab8405 !important;
}

button.cancelbtndept {
  text-transform: none !important;
  border-radius: 2px;
  background: #ffffff !important;
  width: 420px !important;
  height: 42px;
  margin-top: 30px;
  font-weight: 700;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  margin-bottom: 10px;
  box-shadow: none !important;
}

button.disbalesubmitbtndept {
  text-transform: none !important;
  border-radius: 2px;
  box-shadow: none !important;
  background: #ffffff !important;
  margin-top: 80px;
  width: 420px !important;
  height: 42px;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  opacity: 0.5;
  font-weight: 700;
}

.recharts-tooltip-wrapper {
  border: none !important;
}

.recharts-default-tooltip {
  border: none !important;
}

.text-capital {
  text-transform: capitalize !important;
}

.supportcardsecondcolumn .supportEnableButton {
  text-transform: none !important;
}

.searchBarForDataset {
  display: flex;
  width: 100%;
  padding: 10px 10px !important;
  justify-content: right;
  transition: width 2s;
}

#categories-main-container .Mui-selected {
  color: black !important;
  background-color: white !important;
  border: none !important;
}

#categories-main-container .MuiTreeItem-label {
  width: auto !important;
}

.viewdatasetsecondheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1px;
  line-height: 138.69%;

  /* headings */

  color: #3d4a52;
}

.separatorlineview {
  margin-left: -200px;
  margin-right: -200px;
  margin-top: 30px;
  border-top: 1px solid rgba(237, 22, 22, 0.5);
}

.downloadfiles {
  margin-top: 30px;
}

.downloadfiles :hover {
  color: #3491ee !important;
}

.solid {
  border-left: 1px solid #bbb;
  width: 300px;
  margin-left: -100px;
  margin-top: 50px;
}

.horizontal {
  border-left: 1px solid #bbb;
  height: 300px;
}

.thirdmainheadingview {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 138.69%;
  /* or 19px */

  /* headings */

  color: white;
  align-items: center;
  text-align: center;
}

.ant-message-notice-content {
  position: absolute !important;
  left: 0px !important;
}

.dataset_selector_in_integration .MuiSvgIcon-root {
  color: #000000 !important;
}

.dataset_selector_in_integration .row {
  margin-top: 0px !important;
}

.dataset_selector_in_integration .ant-segmented-item-selected {
  margin-bottom: 0px !important;
}

.dataset_selector_in_integration .MuiCollapse-entered {
  background: #ffffff !important;
  box-shadow: 0px 20px 40px -4px rgb(145 158 171 / 16%) !important;
  border-radius: 8px !important;
}

.react-multi-email input {
  min-width: 500px !important;
}

.carousel_in_guest_user_main_page .slick-arrow {
  background-color: #00a94f !important;
  border-radius: 20px !important;
}

.carousel_in_guest_user_main_page {
  display: contents;
}

.back_btn_guest .MuiSvgIcon-root {
  color: #3491ee !important;
}

.dataset_selector_in_integration .MuiFormControlLabel-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dataset_selector_in_integration .deleteicon {
  cursor: pointer;
}

.dataset_selector_in_integration .deleteicon:hover {
  color: red !important;
}

.deleteicon:hover .mainBoxInIntegration {
  border: 1px solid red !important;
}

.dataset_integration_selectors .row {
  background-color: #ab8405 !important;
}

html {
  scroll-behavior: smooth;
}

.MuiInputLabel-asterisk {
  color: red !important;
}

.all_selectors_as_sticky {
  transition: all 0.2s;
}

.ant-affix .all_selectors_as_sticky {
  box-shadow: 0px 2px 5px rgb(0 0 0 / 50%) !important;
  margin: 0px !important;
  padding: 5px 0px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.connectorCard {
  padding: 20px 40px 20px 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #3d4a52;
  line-height: 19px;
  border-radius: 10px;
  width: 368px;
  height: 178px;
  margin: 30px 15px 15px 15px;
  border: 1px solid #ffdb64;
  outline: none;
}

.connectorCard:hover {
  box-shadow: 0px 4px 20px rgba(216, 175, 40, 0.28);
  border: 1px solid #ffdb64;
  cursor: pointer;
}

.dataset_selector_in_integration .backButtonMainDiv .MuiSvgIcon-root {
  color: #3491ee !important;
}

.ant-segmented-group label {
  margin-bottom: 0px !important;
}

.button:active {
  background-color: #00a94f !important;
}

.MuiStepLabel-label {
  font-family: "Montserrat" !important;
}

input,
textarea {
  font-family: "Montserrat" !important;
  font-size: 16px !important;
}

.stepper_ .Mui-completed,
.stepper_ .Mui-active {
  font-weight: 600 !important;
}

.mainOnboardingParent .MuiStepper-root {
  padding: 0px 150px !important;
}

#country_select {
  font-family: "Montserrat" !important;
  font-size: 16px !important;
  text-align: left !important;
}

/* animation for the onboarding/ */
.step-enter {
  opacity: 0;
  transform: translateX(100%);
}

.step-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 500ms, transform 500ms;
}

.step-exit {
  opacity: 1;
  transform: translateX(0%);
}

.step-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms, transform 500ms;
}

#panel1bh-header .MuiInputBase-root:nth-child(1) {
  height: 28px !important;
}

.textarea:active {
  border: none !important;
  outline: none !important;
}

.input_field_subscribe {
  border-radius: 8px !important;
  width: 466px !important;
  height: 56px;
}

.input_field_subscribe
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  padding-right: 0px !important;
  border-radius: 8px !important;
}

.input_field_subscribe_sm {
  border-radius: 8px !important;
  width: 327px !important;
  height: 30px !important;
  padding: 0px;
}

.input_field_subscribe_sm
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  padding-right: 0px !important;
  border-radius: 8px !important;
  height: 30px !important;
  width: 327px !important;
  padding: 0px;
}

.input_field_subscribe_md {
  border-radius: 8px !important;
  width: 273px !important;
  height: 30px !important;
  padding: 0px;
}

.input_field_subscribe_md
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  padding-right: 0px !important;
  border-radius: 8px !important;
  height: 32px !important;
  width: 273px !important;
  padding: 0px;
}

.cursor-pointer {
  cursor: pointer;
}

.ml-11 {
  margin-left: 11px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-44 {
  margin-right: 44px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mr4 {
  margin-right: 4px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mt-39 {
  margin-top: 39px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.fw600 {
  font-weight: 600 !important;
}

.breadcrumbItem:hover {
  color: #00a94f !important;
}

.MuiSnackbar-root {
  top: 95px !important;
}

.category_detail_main_box .MuiInputAdornment-root {
  height: 25px !important;
}

.category_detail_main_box .MuiInputAdornment-root .btn-primary {
  padding: 0px 10px !important;
}

.rich_text_editor select,
.rich_text_editor button {
  border: none !important;
  font-family: "Montserrat" !important;
  text-align: center;
}

.rich_text_editor {
  text-align: left;
}

.rich_text_editor .public-DraftEditorPlaceholder-inner::after {
  content: " *";
  color: red !important;
}

.rich_text_editor .DraftEditor-root,
.DraftEditor-editorContainer,
.public-DraftEditor-content {
  height: 150px !important;
  max-height: 250px !important;
  font-family: "Montserrat" !important;
}

.edit_head_name_accordion {
  width: 950px;
  height: 50px;
  margin: 20px auto !important;
}

.accordions_in_onboarding .MuiAccordionSummary-content {
  align-items: center !important;
  justify-content: space-between;
}

.stepper_ .DraftEditor-editorContainer,
.DraftEditor-root,
.DraftEditor-editorContainer {
  overflow: auto !important;
  display: contents !important;
}

.requestTableContainer .MuiTableCell-root {
  border-left: none !important;
}

.MuiSlider-colorPrimary {
  color: #00a94f !important;
}

.fabIcon {
  background-color: white !important;
}

.ant-drawer-content-wrapper {
  top: 88px !important;
}

.ant_drawer_in_req_api .ant-drawer-title {
  text-align: left;
  font-weight: 700;
  font-size: 24px;
}

.ant_drawer_in_req_api .ant-drawer-body {
  padding: 9px 35px 0px 48px !important;
}

.generate_key_main_box .MuiSvgIcon-root {
  color: white !important;
}

/* .data_table_inside_dataset_details_view .ant-table-header th:first-child {
  background-color: #000000 !important;
} */
/* .data_table_inside_dataset_details_view .ant-table-tbody tr:hover {
  background-color: #beffde !important;
} */
.data_table_inside_dataset_details_view .row-hover,
.data_table_inside_dataset_details_view .ant-table-cell {
  font-family: "Montserrat" !important;
}

.data_table_inside_dataset_details_view .ant-table-cell {
  text-transform: capitalize !important;
}

/* Add this CSS to your stylesheets or CSS-in-JS solution */
.data_table_inside_dataset_details_view .row-hover {
  transition: background-color 0.3s ease;
}

.data_table_inside_dataset_details_view .ant-table-title,
.data_table_inside_dataset_details_view .ant-table-title button {
  font-family: "Montserrat" !important;
}

.data_table_inside_dataset_details_view .row-hover:hover {
  background-color: #beffde !important;
  /* Change this color as needed */
}

.ant-pagination-item-active {
  background-color: #00a94f !important;
  color: white !important;
  border: 1px solid #00a94f !important;
  margin-right: 5px !important;
  font-family: "Montserrat" !important;
}

.ant-pagination-item {
  font-family: "Montserrat" !important;
}

.ant-pagination-options {
  display: none !important;
  visibility: hidden !important;
  font-family: "Montserrat" !important;
}

.select_all_in_api_consume .MuiSvgIcon-root {
  color: white !important;
}

.category_card_on_landing_page_dataset:hover {
  border: 0.5px solid;
  color: #00a94f !important;
}

.first_category_card_on_landing_page_dataset {
  border: 0.5px solid !important;
}

.main_box_for_dataset_listing .ant-card-head-title {
  margin: 20px 0px !important;
}

.main_box_for_dataset_listing .ant-card-head {
  padding: 5px !important;
}

.mt-180 {
  margin-top: 150px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.scrolled {
  height: 0px !important;
  transition: all 2s;
}

.each_list_in_masking_dataset span {
  font-size: 24px !important;
}

.each_list_in_masking_dataset:hover {
  background-color: #eafbf3;
}

.mainBoxForGuestHome .row {
  margin: 0px !important;
}

#click-support-icon {
  display: none !important;
}

.pariticipants_list_and_new_request .MuiTabs-scrollButtons {
  display: none !important;
}

.main_container_for_dashboard_and_api .row {
  margin: 0px !important;
}

.feedback_system .thumbs_down {
  color: red !important;
}
.thumbs_down:hover,
.thumbs_up:hover {
  scale: 1.1;
}
.hidden {
  display: none !important;
}
.tabs_in_view .MuiTabs-flexContainer {
  justify-content: end !important;
}
.custom_button {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  width: fit-content;
  height: 30px; /* Increased height for better visibility */
  border: 1px solid rgba(0, 171, 85, 0.48) !important;
  border-radius: 5px;
  color: #ffffff !important;
  background: #00a94f !important;
  text-transform: none;
  padding: 0 15px; /* Added horizontal padding for a better button shape */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for 3D effect */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for hover effects */
}

.custom_button:hover {
  background: #00873d; /* Slightly darker green for hover effect */
  transform: translateY(-2px); /* Subtle lift effect on hover */
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.2); /* Increased shadow on hover for depth */
}

.custom_button:active {
  transform: translateY(1px); /* Button presses down on click */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1); /* Less shadow on active to mimic pressing */
}
.MuiTabs-flexContainer .MuiButtonBase-root,
.MuiTableCell-root {
  font-family: "Montserrat" !important;
}

.select_menu_option {
  font-family: "Montserrat" !important;
  font-size: 12px !important;
}
